<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <!-- <div class="formDiv" style="width: 70vw; margin-left: 20vw;">
          <el-form label-position="left" label-width="90px">
            <el-row :gutter="20">
             
              <el-col :span="10">
                <el-form-item label="运营商:">
                  <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                    <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                  </el-select>
                </el-form-item>
              </el-col>
             
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
           
          </el-row>
        </el-form>
      </div> -->

      <div class="title">电动车充电金额模板管理</div>

      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData1"
          border
          v-loading="loading"
          height="530px"
          style="width: 90%; margin: 30px auto; overflow-y: auto"
        >
          <el-table-column label="模板id" prop="id" />
          <el-table-column label="模板名称" prop="name" />
          <el-table-column label="操作" width="200" align="center">
            <template #default="scope">
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="detail(scope.$index, scope.row)"
                >详情</el-button
              >
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  @click="detail(scope.$index, scope.row)"
                >
                  详情
                </div>
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  编辑
                </div> -->
              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  @click="del(scope.$index, scope.row)"
                >
                  删除
                </div> -->
            </template>
          </el-table-column>
        </el-table>

        <!-- <div class="pagination">
            <el-pagination
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[5, 10, 15, 20]"
              :small="small"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div> -->
      </div>
      <div v-dialogdrag>
        <el-dialog
          v-model="addRoleDiaLogFlag"
          :title="DiaLogFlagTitle"
          width="820px"
          :before-close="closeDialog"
          class="dialog"
        >
          <div class="formDiv">
            <el-form
              ref="FormRules"
              :rules="rules"
              label-position="right"
              label-width="100px"
            >
              <el-row :gutter="20">
                <el-col :span="9">
                  <el-form-item label="">
                    <el-input
                      type="number"
                      :disabled="isdisabled1"
                      v-model="FormData1[0]"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="">
                    <el-input
                      type="number"
                      :disabled="isdisabled1"
                      v-model="FormData1[1]"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="9">
                  <el-form-item label="">
                    <el-input
                      type="number"
                      :disabled="isdisabled1"
                      v-model="FormData1[2]"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="9">
                    <el-form-item label="">
                      <el-input type="number" :disabled="isdisabled1" v-model="FormData1[3]"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="9">
                    <el-form-item label="">
                      <el-input type="number" :disabled="isdisabled1" v-model="FormData1[4]"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9">
                    <el-form-item label="">
                      <el-input type="number" :disabled="isdisabled1" v-model="FormData1[5]">元</el-input>
                    </el-form-item>
                  </el-col> -->
              </el-row>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="handleClose">关闭</el-button>
              <el-button
                class="Btn"
                @click="submitDialog"
                v-show="isedit"
                type="primary"
                >提交</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- <div style="margin-top: 20px;">
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled1" v-model="FormData1[0]"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled1" v-model="FormData1[1]"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled1" v-model="FormData1[2]"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled1" v-model="FormData1[3]"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled1" v-model="FormData1[4]"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled1" v-model="FormData1[5]"></el-input>
                </el-col>
            </el-row>
        </div>
        
        <div class="buttons">
            <div class="btnDiv">
                <el-button @click="handleEdit1()">编辑</el-button>
            </div>
            <div class="btnDiv">
                <el-button @click="submit1()">提交</el-button>
            </div>
        </div> -->

      <!-- </div> -->

      <!-- <div class="s1">
        <div class="title">电动汽车模板管理</div>
        
        <div style="margin-top: 20px;">
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled2" v-model="FormData2.figure1"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled2" v-model="FormData2.figure2"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled2" v-model="FormData2.figure3"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled2" v-model="FormData2.figure4"></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="4"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled2" v-model="FormData2.figure5"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="8">
                    <el-input type="number" :disabled="isdisabled2" v-model="FormData2.figure6"></el-input>
                </el-col>
            </el-row>
        </div>
        <div class="buttons">
            <div class="btnDiv">
                <el-button @click="handleEdit2()">编辑</el-button>
            </div>
            <div class="btnDiv">
                <el-button @click="submit2()">提交</el-button>
            </div>
        </div>
        
        
       
      
      </div> -->
    </div>
  </div>
</template>
  <script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { set_amount, amend_money, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      tableData1: [
        {
          name: "默认电动车充电金额模板",
          id: 1,
        },
      ],
      addRoleDiaLogFlag: false, // 弹窗显示
      DiaLogFlagTitle: "", // 弹窗title
      isAdd: "", // 1 是详情 0 是编辑
      isdisabled1: true,
      // isdisabled2:true,
      FormData1: [],
      FormData: {},
      users: [],
      isedit: true,
    });

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    const getList = () => {
      const dataa = {
        type_id: 2,
      };
      amend_money(dataa).then((res) => {
        data.FormData1 = res.data;
      });
    };

    const submitDialog = () => {
      const dataa = {
        type_id: 2,
        figure1: Number(data.FormData1[0]),
        figure2: data.FormData1[1],
        figure3: data.FormData1[2],
        figure4: data.FormData1[3],
        figure5: data.FormData1[4],
        figure6: data.FormData1[5],
      };
      for (var key in dataa) {
        if (dataa[key] == "") {
          delete dataa[key];
        }
      }
      console.log(dataa, "00000");

      set_amount(dataa).then((res) => {
        if (res.code == 200) {
          ElMessage.success(res.msg);
          data.addRoleDiaLogFlag = false;
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      });
      data.isdisabled1 = true;
    };
    // 详情
    const detail = () => {
      data.addRoleDiaLogFlag = true;
      data.DiaLogFlagTitle = "模板详情";
      data.isAdd = "1";
      data.isdisabled1 = true;
      data.isedit = false;
    };
    // 编辑
    const handleEdit = () => {
      // data.editUser = row;
      data.isAdd = "0";
      data.DiaLogFlagTitle = "编辑模板";
      data.addRoleDiaLogFlag = true;
      data.isdisabled1 = false;
      data.isedit = true;
    };

    const handleClose = () => {
      data.addRoleDiaLogFlag = false;
    };

    onMounted(() => {
      // getUsername();
      // getList();
    });
    return {
      ...toRefs(data),
      getUsername,
      getList,
      handleClose,
      submitDialog,
      detail,
      handleEdit,
    };
  },
};
</script>
  <style scoped>
.formDiv {
  margin-top: 20px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 288px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch {
  float: left;
  width: 70px;
  margin: 0 4px;
  margin-top: 8px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.flex {
  display: flex;
}
.s1 {
  width: 50% !important;
  height: 50%;
}

.title {
  margin-left: 5%;
}
.buttons {
  width: 200px;
  margin: 10px auto;
  display: flex;
}
</style>
  